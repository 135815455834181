import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"
import { MarkdownViewer } from "../components/Markdown"
import Button from "../components/Buttons/Button"
import { ImpactSlider, TextSlide } from "../components/Sliders"
import Video from "../components/Video/Video"
import DesktopSizzle from "../video/sizzle--desktop.mp4"

const Homepage = ({ pageContext, data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { heading, cards, mapImage, toast, slider } = post
  return (
    <Layout className="main-homepage">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={post.schemaPage}
        pathname={location.pathname}
      />
      <div className="homepage">
        <Section zeroTop colorBack>
          <TextSlide />
        </Section>

        <Section>
          <Container>
            <h2 className="has-text-centered" style={{ fontSize: "3rem" }}>
              Even the incomplete story poles show the devastating impact the
              development would have on our city.
            </h2>
          </Container>
        </Section>

        <Section>
          <Container>
            <div className="image-collage">
              <ImageMeta publicId="BRIG/DEV/building-outline-1" />
              <ImageMeta publicId="BRIG/DEV/building-outline-2" />
              <ImageMeta publicId="BRIG/DEV/building-outline-3" />
              <ImageMeta publicId="BRIG/DEV/building-outline-4" />
            </div>
          </Container>
        </Section>

        <Section colorBack>
          <Container>
            <MarkdownViewer markdown={slider.text} />
          </Container>
        </Section>

        <ImpactSlider slides={slider.slider} />

        <div className="home-alert-toast">
          <Container>
            <div className="home-alert-toast__content">
              <MarkdownViewer markdown={toast.text} />
              <Button
                buttonText={toast.button.buttonText}
                appearance={toast.button.appearance}
                internal={toast.button.destination === "internal"}
                href={toast.button.href}
              />
            </div>
          </Container>
        </div>

        <Section>
          <Container>
            <MarkdownViewer markdown={heading} />
          </Container>
        </Section>

        {/* <div className="voices-banner">
          <Container className="mb-0 mt-0">
            <a href="/voices/#public-comments" title="See community voices">
              <h3 className="has-text-centered">
                See why BRIG finds the developer's "Final Application" to be
                incomplete.
              </h3>
            </a>
          </Container>
        </div> */}

        {/* <Video src={DesktopSizzle} className="home-sizzle" /> */}

        <Section>
          <Container>
            <div className="grid-even-2">
              {cards.map((card, i) => (
                <div key={i} className="home-card">
                  <h3>{card.heading}</h3>
                  <MarkdownViewer markdown={card.blurb} />
                  <Button
                    buttonText={card.button.buttonText}
                    appearance={card.button.appearance}
                    anchor
                    href={card.button.href}
                  />
                </div>
              ))}
            </div>
          </Container>
        </Section>

        <ImageMeta cloudName="nuvolum" width="auto" publicId={mapImage} />
      </div>
    </Layout>
  )
}

Homepage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const pageQuery = graphql`
  query basicquery {
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        schemaPage
        metaTitle
        metaDescription
        heading
        mapImage
        toast {
          text
          button {
            buttonText
            href
            destination
            appearance
          }
        }
        cards {
          heading
          blurb
          button {
            buttonText
            appearance
            destination
            href
          }
        }
        slider {
          text
          slider {
            button {
              destination
              appearance
              buttonText
              href
            }
            image
            imageMobile
            heading
          }
        }
        schemaPage
      }
    }
  }
`

export default Homepage
